/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import environment from '../../../environments/environment';
import './index.css';

class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      hasClicked: false,
    };
    this.animationInstance = null;
    this.selectItem = this.selectItem.bind(this);
  }

  makeShot = (particleRatio, opts) => {
    if (this.animationInstance) {
      this.animationInstance({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
    }
  };

  fire = () => {
    this.makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    this.makeShot(0.2, {
      spread: 60,
    });

    this.makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  notify(text) {
    toast.error(text, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  selectItem() {
    this.props.setTriggerWheel(true);
    if (this.state.selectedItem === null && this.state.hasClicked === false) {
      const { selectedItem } = this.props;
      // Math.floor(Math.random() * this.props.items.length);
      this.props.onSelectItem(this.props.items[selectedItem]);

      this.setState({ selectedItem });
      this.state.hasClicked = true;

      setTimeout(() => {
        this.fire();
      }, 3600);
      setTimeout(async () => {
        this.displayModale();
        if (this.props.wheel.claimImediate) {
          const data = {
            user: this.props.user,
            wheelId: this.props.wheel._id,
            userSeed: this.props.uuid,
            wheelCtaType: this.props.pickedCta.type,
            marketing: this.props.pickedCta.type === 'form',
            gift: {
              name: this.props.items[selectedItem].name,
              ...(this.props.items[selectedItem].digitalOffer && {
                digitalOffer: this.props.items[selectedItem].digitalOffer,
              }),
              ...(this.props.items[selectedItem].discountType && {
                discountType: this.props.items[selectedItem].discountType,
              }),
            },
          };
          axios
            .post(`${environment.REACT_APP_API_URL}/wheels-history/${this.props.wheelHistoryId}`, data)
            .catch((err) => {
              console.log(err.response.data.message);
              this.notify(err.response.data.message);
            });
        }
      }, 5500);
    }
  }

  displayModale() {
    this.props.setDisplaySecondModal(true);
  }

  render() {
    const { selectedItem } = this.state;
    const { items, triggerWheel } = this.props;

    if (triggerWheel) {
      this.selectItem();
    }

    const wheelVars = {
      '--nb-item': items?.length,
      '--selected-item': selectedItem,
    };
    const spinning = selectedItem !== null ? 'spinning' : '';

    return (
      <div
        style={{
          '--wheel-border-color': this.props.style?.wheelBorderColor,
          '--wheel-text-color': this.props.style?.wheelTextColor,
          '--wheel-color': this.props.style?.wheelColor,
          '--wheel-item-color': this.props.style?.wheelItemColor,
        }}
        className="wheel-container"
      >
        <div className="wheel-logo">
          <img
            className='rounded-full'
            src={this.props.shop.logo}
            alt={`${this.props?.shop.name} logo`}
          />
        </div>
        <div className="wheel-selector">
          <div className="inside" />
        </div>
        <div
          className={`wheel ${spinning}`}
          style={wheelVars}
          onClick={this.selectItem}
          role="button"
          onKeyDown={(e) => {
            if (e.key === 'Enter') this.selectItem();
          }}
          tabIndex="0"
        >
          {items?.map((item, index) => (
            <div
              className="wheel-item"
              key={item.name}
              style={{ '--item-nb': index }}
            >
              <svg viewBox="0 0 500 500">
                <path
                  transform="translate(0, -200)"
                  id="curve"
                  fill="transparent"
                  d="M275 0A275 275 90 11275 550 275 275 90 11275 0"
                />
                <text textAnchor="middle" width="500">
                  <textPath
                    xlinkHref="#curve"
                    startOffset="50.5%"
                    fill={this.props?.style?.wheelTextColor}
                  >
                    {item.digitalOffer
                      ? `-${item.name}${item.discountType === 'percent' ? '%' : '€'
                      }`
                      : item.name}
                  </textPath>
                </text>
              </svg>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isModaleDisplayed: state.isModaleDisplayed,
  giftRecived: state.giftRecived,
});

export default connect(mapStateToProps)(Wheel);
