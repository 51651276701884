import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './index.css';
import environment from '../../../environments/environment';

const dayjs = require('dayjs');

export default function ClaimGift() {
  const [history, setHistory] = useState(undefined);
  const [wheel, setWheel] = useState(undefined);
  const [shop, setShop] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isAlreadyUsed, setIsAlreadyUsed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [shopCode, setShopCode] = useState('');

  const [selectedItem, setSelectedItem] = useState(undefined);
  const [infos, setInfos] = useState(undefined);
  const { search } = useLocation();
  const gift = new URLSearchParams(search).get('gift');

  useEffect(() => {
    axios
      .get(`${environment.REACT_APP_API_URL}/wheels-history/${gift}`)
      .then((res) => {
        setIsAlreadyUsed(res.data.isGiftReceived);

        setHistory(res.data);
        setWheel(res.data.wheelId);
        setShop(res.data.wheelId.shopId);
        setIsMounted(true);
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      //
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .put(
        `${environment.REACT_APP_API_URL}/wheels-history/${history._id}/claim-gift`,
        {
          shopCode,
        }
      )
      .then((res) => {
        setTimeout(() => {
          setIsClaimed(true);
        }, 1000);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
      });
  };
  return (
    <div>
      {isMounted ? (
        <div className="claim-gift">
          <div className="container">
            <div className="brand-header">
              <div className="logo">
                <img className="brand-logo" src={shop.logo} alt="Logo" />
              </div>
              <div className="brand-name">{shop.name}</div>
            </div>

            {isClaimed && (
              <div className="content">
                <p>Recupération confirmé, vous pouvez fermer cette page.</p>
              </div>
            )}

            {!isClaimed && isAlreadyUsed && (
              <div className="content">
                <p>Vous avez déjà réclamé ce cadeau.</p>
              </div>
            )}

            {!isClaimed && !isAlreadyUsed && (
              <div>
                <div className="content">
                  <p>
                    Bravo, vous avez gagné{' '}
                    <span className="gift-name-text">
                      {history?.gift?.name}
                    </span>{' '}
                    à venir récupérer directement chez{' '}
                    <span className="brand-name-text">{shop.name}</span> ! 🎁
                  </p>
                  <p>Pour récupérer votre cadeau rien de plus simple :</p>
                  <ol className="number-list">
                    <li>
                      Montrer cet écran à un membre de l&apos;équipe lorsque vous
                      vous présenterez dans l&apos;établissement 📲
                    </li>
                    <li>
                      Une fois le cadeau récupéré, cette même personne se
                      chargera de rentrer le code et de cliquer sur le bouton
                      ci-dessous ✅
                    </li>
                  </ol>
                </div>
              </div>
            )}

            {!isClaimed && !isAlreadyUsed ? (
              <div className="claim-gift-footer">
                {wheel?.minimumOrder > 0 && (
                  <span className="warning-text">
                    min commande {wheel?.minimumOrder}€ et offre non cumulable
                    avec une offre déjà existante
                    <br />
                  </span>
                )}
                {dayjs().isBefore(dayjs(history?.availableDate), 'day') ? (
                  <div>
                    <p>
                      Votre cadeau sera disponible à partir de la date du :{' '}
                      <span className="gift-name-text">
                        {dayjs(history.availableDate).format('DD/MM/YYYY')} Vous
                        ne pouvez pas le récupérer pour le moment.{' '}
                      </span>{' '}
                    </p>
                  </div>
                ) : (
                  <span className="warning-text">
                    (Attention c&apos;est à une personne de l&apos;établissement de
                    remplir le champ ci-dessous)
                  </span>
                )}
                {dayjs().isAfter(dayjs(history?.maxAvailableDate), 'day') ? (
                  <div>
                    <p>
                      Votre cadeau était disponible jusqu&apos;à la date du :{' '}
                      <span className="gift-name-text">
                        {dayjs(history.maxAvailableDate).format('DD/MM/YYYY')} Vous
                        ne pouvez plus le récupérer.
                      </span>{' '}
                    </p>
                  </div>
                ) : (
                  <span className="warning-text" />
                )}

                <input
                  type="text"
                  id="mail"
                  name="mail"
                  className="custom-form"
                  placeholder="Saisissez le code établissement"
                  autoComplete="off"
                  value={shopCode}
                  onChange={(e) => setShopCode(e.target.value)}
                  required
                />
                <span
                  style={{
                    fontType: 'italic',
                    color: 'red',
                  }}
                >
                  {errorMessage}
                </span>
                <button
                  className="google-button"
                  type="button"
                  disabled={
                    dayjs().isBefore(dayjs(history.availableDate), 'day') ||
                    dayjs().isAfter(dayjs(history.maxAvailableDate), 'day')
                  }
                  style={{
                    width: '100%',
                    background: wheel?.customStyle?.buttonColor,
                  }}
                  onClick={handleClick}
                >
                  <span className="google-button__text">
                    J&apos;ai remis le cadeau au client
                  </span>
                </button>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
