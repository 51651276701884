/* eslint-disable react/destructuring-assignment */
import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        const { display } = this.props;

        return (
            <div className={`modalde ${display ? 'opened-modal' : null}`}>
                <div className="modal" >
                    <div className="modal-wrap">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
