import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import ClaimGift from './components/claim-gift';
import Wheel from './components/wheel';
import appendData from './redux/action';

import './App.css';

import '@fontsource/montserrat';
import Cards from './components/cards';
import Modal from './components/modal';
import MailForm from './components/mail-form';
import FirstModalForm from './components/firstModalForm';
import environment from '../environments/environment';
import Links from './components/links/Links';

function preventDefault(e) {
  e.preventDefault();
}

function disableScroll() {
  document.body.addEventListener('touchmove', preventDefault, {
    passive: false,
  });
}
function enableScroll() {
  document.body.removeEventListener('touchmove', preventDefault);
}

function Home(props) {
  const [items, setItems] = useState([]);
  const [wheel, setWheel] = useState(undefined);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [selectedGift, setSelectedGift] = useState(undefined);
  const [displayFirstModal, setDisplayFirstModal] = useState(true);
  const [displaySecondModal, setDisplaySecondModal] = useState(false);
  const [triggerWheel, setTriggerWheel] = useState(false);
  const [shop, setShop] = useState({});
  const [wheelId, setWheelId] = useState({});
  const [customStyle, setCustomStyle] = useState(undefined);
  const [customText, setCustomText] = useState(undefined);
  const [pickedCta, setPickedCta] = useState('');
  const [gameType, setGameType] = useState('');
  const [token, setToken] = useState('');
  const [user, setUser] = useState({});
  const [wheelHistoryId, setWheelHistoryId] = useState('');
  const { search } = useLocation();
  const qrCode = new URLSearchParams(search).get('qrCode');

  const generateRandomToken = () =>
    `${Date.now()}${Math.floor(Math.random() * 100)}`;

  const retrieveUniqueToken = () => {
    const tokenRetrieved = Cookies.get('id');
    if (tokenRetrieved) return tokenRetrieved;
    const randomToken = generateRandomToken();
    Cookies.set('id', randomToken, { expires: 182 });
    return randomToken;
  };

  const onSelectItem = (gift) => {
    setSelectedItem(gift.id);
    setSelectedGift(gift);
  };

  useEffect(() => {
    const tokenL = retrieveUniqueToken();
    setToken(tokenL);
    axios
      .post(`${environment.REACT_APP_API_URL}/wheels/randomWheels/${qrCode}`, {
        uuid: tokenL,
      })
      .then((res) => {
        setItems(res.data.wheel.offers);
        setWheel(res.data.wheel);
        setSelectedItem(res.data.selectedItem);
        setShop(res.data.wheel.shopId);
        setWheelId(res.data.wheel._id);
        setCustomStyle(res.data.wheel?.customStyle);
        setCustomText(res.data.wheel?.customText);
        setPickedCta(res.data?.pickedCta);
        setGameType(res.data?.wheel?.gameType);
        setWheelHistoryId(res.data.wheelHistoryId);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div
        style={{ backgroundColor: customStyle?.backgroundColor }}
        className="upperContainer"
      >
        <div className="container">
          <div
            style={{ color: customStyle?.primaryTextColor }}
            className="preTitle"
          >
            {shop?.name}
            <br /> a un cadeau pour vous
            <p style={{ color: customStyle?.secondaryTextColor }}>
              {customText?.secondaryText}
            </p>
          </div>
          {(gameType === 'wheel' || gameType === 'raffle') && (
            <>
              <button
                className="clickToTurnWheel"
                type="button"
                style={{
                  color: customStyle?.buttonTextColor,
                  '--wheel-button-color': customStyle?.buttonColor,
                }}
                disabled={triggerWheel}
                onClick={() => setTriggerWheel(true)}
              >
                Tourner la roue
              </button>
              <div className="roue">
                <Wheel
                  user={user}
                  style={customStyle}
                  shop={shop}
                  items={items}
                  pickedCta={pickedCta}
                  wheel={wheel}
                  uuid={token}
                  selectedItem={selectedItem}
                  triggerWheel={triggerWheel}
                  setTriggerWheel={setTriggerWheel}
                  onSelectItem={onSelectItem}
                  setDisplayFirstModal={setDisplayFirstModal}
                  setDisplaySecondModal={setDisplaySecondModal}
                  wheelHistoryId={wheelHistoryId}
                />
              </div>
            </>
          )}
          {gameType === 'cards' && (
            <>
              <div
                className="clickToShuffle"
                style={{
                  color: customStyle?.buttonTextColor,
                  '--wheel-button-color': customStyle?.buttonColor,
                }}
                disabled={triggerWheel}
                onClick={() => setTriggerWheel(true)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') setTriggerWheel(true);
                }}
                aria-disabled={triggerWheel}
                role="button"
              >
                {!triggerWheel ? 'Melanger les cartes' : 'Choisissez une carte'}
              </div>
              <div className="cards">
                <Cards
                  style={customStyle}
                  items={items}
                  pickedCta={pickedCta}
                  wheel={wheel}
                  uuid={token}
                  triggerWheel={triggerWheel}
                  setTriggerWheel={setTriggerWheel}
                  onSelectItem={onSelectItem}
                  shop={shop}
                  setDisplayFirstModal={setDisplayFirstModal}
                  setDisplaySecondModal={setDisplaySecondModal}
                  wheelHistoryId={wheelHistoryId}
                />
              </div>
            </>
          )}

          <div className="modale">
            {pickedCta.type !== 'none' && (
              <Modal display={displayFirstModal}>
                <FirstModalForm
                  wheel={wheel}
                  shop={shop}
                  setUser={setUser}
                  setDisplayFirstModal={setDisplayFirstModal}
                  displayFirstModal={displayFirstModal}
                  customStyle={customStyle}
                  pickedCta={pickedCta}
                />
              </Modal>
            )}
            <Modal display={displaySecondModal}>
              {selectedGift !== undefined && (
                <MailForm
                  name={wheel?.shopId?.name}
                  wheelId={wheelId}
                  shop={shop}
                  giftRecived={selectedGift}
                  isLose={items[selectedItem]?.isLose}
                  uuid={token}
                  user={user}
                  customStyle={customStyle}
                  pickedCta={pickedCta}
                  sharedWheel={wheel?.sharedWheel}
                  sharedGift={wheel?.sharedGift}
                  wheel={wheel}
                  wheelHistoryId={wheelHistoryId}
                />
              )}
            </Modal>
          </div>
        </div>
      </div>
      <footer
        style={{
          textAlign: 'center',
          paddingHorizontal: 5,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="footer"
      >
        <a
          style={{ color: customStyle?.primaryTextColor, fontSize: '10px' }}
          href="https://www.dokaa.app/dokaa-conditions-de-participation"
          target="_blank"
          rel="noreferrer"
        >
          Conditions générales d&apos;utilisation
        </a>
      </footer>
    </>
  );
}

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/links/:slug" element={<Links />} />
          <Route exact path="/claim-gift" element={<ClaimGift />} />
        </Routes>
      </div>
    </Router>
  );
}

const mapDispatchToProps = {
  appendData,
};

const mapStateToProps = (state) => ({
  wheelId: state.wheelId,
  giftRecived: state.giftRecived,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
