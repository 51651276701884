/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import MoonLoader from 'react-spinners/MoonLoader';
import square from '../../ressources/pictures/square.png';
import stars from '../../ressources/pictures/etoiles.png';
import gift from '../../ressources/pictures/gift2.svg';
import Disclaimer from '../disclaimer';

const socialTypes = ['facebook', 'instagram', 'tiktok'];
const integrationTypes = [
  'tripadvisor',
  'pagesjaunes',
  'deliveroo',
  'trustpilot',
  'google',
];

function FirstModalForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);

  function openInNewTab(href) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href,
    }).click();
  }

  useEffect(() => {
    if (props?.pickedCta) {
      setLoaded(true);
    }
  }, [props]);

  const openLink = (type) => {
    // google security before mig:
    const { reviewUrl } = (props?.pickedCta.type === 'instagram' ||
      props?.pickedCta.type === 'tiktok' ||
      props?.pickedCta.type === 'facebook') ? props.shop.socials[props?.pickedCta.type] : props.shop.integrations[props?.pickedCta.type];
    if (type === 'google' && (!reviewUrl || reviewUrl === '')) {
      openInNewTab(
        `https://search.google.com/local/writereview?placeid=${props.shop.integrations.google.googlePlaceId}`
      );
      return;
    }

    if (type === 'custom') {
      openInNewTab(props?.wheel?.customCounterpartLink);
    } else if (socialTypes.includes(type)) {
      const socialUrl = props.shop.socials[type];
      openInNewTab(socialUrl);
    } else if (integrationTypes.includes(type)) {
      openInNewTab(props.shop.integrations[props?.pickedCta.type].reviewUrl);
    }
  };

  const handleClick = (e) => {
    const type = props?.pickedCta?.type;
    e.preventDefault();
    openLink(type);
    setIsLoading(true);
    setTimeout(() => {
      props.setDisplayFirstModal(false);
    }, 15000);
  };

  const handleClickMessage = (e) => {
    e.preventDefault();
    openLink(props?.pickedCta.type);
  };

  const handleDisclaimerClick = () => {
    openLink(props?.pickedCta.type);
    setIsLoading(true);
    setTimeout(() => {
      props.setDisplayFirstModal(false);
    }, 15000);
  };

  const handleDisclaimer = () => {
    setOpenDisclaimer(true);
  };

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(
        /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/,
        'Numéro de téléphone invalide'
      )
      .required('Le numéro de téléphone est obligatoire'),
  });

  if (!loaded) {
    return null;
  }

  if (openDisclaimer) {
    return (
      <Disclaimer
        handleClick={handleDisclaimerClick}
        customStyle={props?.customStyle}
        setOpenDisclaimer={setOpenDisclaimer}
        pickedCta={props?.pickedCta}
      />
    );
  }

  const type = props?.pickedCta?.type;
  const displayNameType =
    (type || '').charAt(0).toUpperCase() + (type || '').slice(1);

  return (
    <div className="first-modal-form">
      {isLoading ? (
        <>
          <div className="title-modal">
            patience... <br /> plus que quelques secondes
          </div>
          {integrationTypes.includes(type) && (
            <div style={{ fontSize: '12px', margin: 'auto' }}>
              Nous vérifions votre avis, ne quittez pas cette page.
            </div>
          )}
          {socialTypes.includes(type) && (
            <div style={{ fontSize: '12px', margin: 'auto' }}>
              {' '}
              Nous vérifions votre abonnement, ne quittez pas cette page.
            </div>
          )}
          <div className="loading-spinner">
            <MoonLoader color="#f3c144" size={30} speedMultiplier={0.4} />
          </div>
          <p style={{ fontSize: '12px', padding: 'none' }}>
            {socialTypes.includes(type) && (
              <>
                Vous n&apos;etes pas encore abonné(e)?
                <br />
              </>
            )}
            {integrationTypes.includes(type) && (
              <>
                Vous n&apos;avez pas encore donné(e) votre avis ?<br />
              </>
            )}
          </p>
          {props?.pickedCta.type !== 'form' && (
            <button
              className="google-button"
              disabled={!props.shop}
              style={{
                maxWidth: '100%',
                background: props?.customStyle?.buttonColor,
              }}
              onClick={handleClickMessage}
              type="button"
            >
              {socialTypes.includes(type) && (
                <span className="google-button__text">Abonnez-vous</span>
              )}
              {integrationTypes.includes(type) && (
                <span className="google-button__text">
                  Donnez un avis{' '}
                  {(props?.pickedCta.type || '').charAt(0).toUpperCase() +
                    (props?.pickedCta.type || '').slice(1)}
                </span>
              )}

              {props?.pickedCta.type === 'custom' && (
                <span className="google-button__text">
                  Retourner sur la page
                </span>
              )}
            </button>
          )}
        </>
      ) : (
        <>
          {props.shop.subscriptions.games.enable ? (
            <>
              {!(
                props.wheel.limitedRedem &&
                props.wheel.numberRedemed >= props.wheel.limitOfRedem
              ) ? (
                <>
                  {integrationTypes.includes(type) && (
                    <>
                      <div className="title-modal">Votre avis compte</div>
                      <div className="subtitle-modal">
                        Laissez nous un avis sur{' '}
                        <strong>
                          {(props?.pickedCta.type.split('')[0].toUpperCase() ||
                            '') + (props?.pickedCta.type.slice(1) || '')}
                        </strong>{' '}
                        et repartez avec un cadeau{' '}
                        <img
                          className="gift-icon"
                          src={gift}
                          alt="square"
                          style={{ left: '50%', transform: 'translateX(-50%)' }}
                        />
                      </div>
                    </>
                  )}

                  {socialTypes.includes(type) && (
                    <>
                      <div className="title-modal">Votre abonnement compte</div>
                      <div className="subtitle-modal">
                        Abonnez vous à notre compte{' '}
                        <strong>{displayNameType}</strong> et repartez avec un
                        cadeau{' '}
                        <img className="gift-icon" src={gift} alt="square" />
                      </div>
                    </>
                  )}

                  {props?.pickedCta.type === 'custom' && (
                    <>
                      <div className="title-modal">PAR ICI LES BONS PLANS</div>
                      <div className="subtitle-modal">
                        Suivez les étapes et revenez pour participer{' '}
                        <img className="gift-icon" src={gift} alt="square" />
                      </div>
                    </>
                  )}
                  {props?.pickedCta.type === 'noMoreCta' && (
                    <div className="title-modal">
                      Vous avez déjà joué a ce jeu :(
                    </div>
                  )}
                  {props?.pickedCta.type === 'wheelCooldown' && (
                    <div className="title-modal">
                      Vous avez déjà joué à ce jeu il y a moins de 24h,
                      réessayez plus tard :(
                    </div>
                  )}
                  {props?.pickedCta.type === 'form' && (
                    <div>
                      <div className="title-modal">
                        Remplissez ce formulaire et gagnez un cadeau !
                      </div>
                      <Formik
                        initialValues={{
                          lastName: '',
                          firstName: '',
                          phone: '',
                          email: '',
                        }}
                        onSubmit={async (values) => {
                          const formInvalid = Object.values(values).some(
                            (value) => value === null || value === ''
                          );
                          setIsFormInvalid(formInvalid);

                          if (!formInvalid) {
                            props?.setUser(values);
                            setIsLoading(true);
                            setTimeout(() => {
                              props.setDisplayFirstModal(false);
                            }, 1000);
                          }
                          return null;
                        }}
                        validationSchema={validationSchema}
                      >
                        {({ errors, touched }) => (
                          <Form
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Field
                              class="custom-form"
                              id="lastName"
                              name="lastName"
                              placeholder="Nom"
                            />
                            <Field
                              class="custom-form"
                              id="firstName"
                              name="firstName"
                              placeholder="Prénom"
                            />
                            <Field
                              class="custom-form"
                              id="phone"
                              name="phone"
                              placeholder="Téléphone"
                            />
                            <Field
                              class="custom-form"
                              id="email"
                              name="email"
                              placeholder="Adresse mail"
                              type="email"
                            />
                            {errors.phone && touched.phone ? (
                              <div style={{ color: 'red' }}>{errors.phone}</div>
                            ) : null}
                            <button
                              className="google-button"
                              type="submit"
                              style={{
                                marginTop: '20px',
                                width: '100%',
                                background: props?.customStyle?.buttonColor,
                              }}
                            >
                              <span className="google-button__text">Jouer</span>
                            </button>
                            <span
                              style={{
                                fontSize: '12px',
                                padding: 'none',
                              }}
                            >
                              En participant à ce jeu j&apos;accepte de recevoir
                              des offres promotionnelles
                            </span>
                          </Form>
                        )}
                      </Formik>
                      {isFormInvalid && (
                        <p
                          style={{
                            color: '#df545d',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          Merci de remplir tous les champs.
                        </p>
                      )}
                    </div>
                  )}
                  {props?.pickedCta.type !== 'noMoreCta' &&
                    props?.pickedCta.type !== 'wheelCooldown' &&
                    props?.pickedCta.type !== 'form' && (
                      <>
                        <ol className="number-list first-list my-auto">
                          {integrationTypes.includes(type) && (
                            <li className="flex items-center">
                              Laissez-nous un avis
                              <img
                                className="h-8 ml-2"
                                src={stars}
                                alt="square"
                              />
                            </li>
                          )}
                          {socialTypes.includes(type) && <li>Abonnez-vous</li>}
                          {props?.pickedCta.type === 'custom' && (
                            <li>Suivez les étapes</li>
                          )}
                          {props?.pickedCta.type === 'krys' && (
                            <li className="flex items-center">
                              Laissez-nous un avis
                              <img
                                className="h-8 ml-2"
                                src={stars}
                                alt="square"
                              />
                            </li>
                          )}
                          <li className="flex items-center">
                            Revenez sur cette page en cliquant sur
                            <img
                              className="h-4 ml-2"
                              src={square}
                              alt="square"
                            />
                          </li>
                          <li>
                            <div className="flex items-center">
                              Tournez la roue et découvrez votre cadeau !
                              <img className="h-4" src={gift} alt="square" />
                            </div>
                          </li>
                        </ol>
                        {socialTypes.includes(type) && (
                          <button
                            className="google-button"
                            disabled={!props.shop}
                            style={{
                              maxWidth: '100%',
                              background: props?.customStyle?.buttonColor,
                            }}
                            onClick={handleDisclaimer}
                            type="button"
                          >
                            <span className="google-button__text">
                              Abonnez-vous
                            </span>
                          </button>
                        )}
                        {!socialTypes.includes(type) && (
                          <button
                            type="button"
                            className="google-button"
                            disabled={!props.shop}
                            style={{
                              maxWidth: '100%',
                              background: props?.customStyle?.buttonColor,
                            }}
                            onClick={handleClick}
                          >
                            {props.wheel && (
                              <>
                                {integrationTypes.includes(type) && (
                                  <span className="google-button__text">
                                    Laisser un avis
                                  </span>
                                )}

                                {props?.pickedCta.type === 'custom' && (
                                  <span className="google-button__text">
                                    Commencer
                                  </span>
                                )}
                              </>
                            )}
                          </button>
                        )}
                      </>
                    )}
                </>
              ) : (
                <>
                  <div className="title-modal">
                    Ce jeu n&apos;est malheureusement plus disponible
                  </div>
                  <div className="subtitle-modal">
                    Veuillez réessayer ultérieurement..
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="title-modal">
                Ce jeu n&apos;est malheureusement plus disponible
              </div>
              <div className="subtitle-modal">
                Veuillez réessayer ultérieurement..
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default FirstModalForm;
