/* eslint-disable react/destructuring-assignment */
/* eslint-disable global-require */
import './index.css';

function DisclaimerModal(props) {
  return (
    <div className="disclaimer-form">
      <img
        className="safari"
        src={require(`../../ressources/pictures/safari.jpeg`)}
        alt="safari"
      />
      <img
        className="pointer"
        src={require(`../../ressources/pictures/pointer.png`)}
        alt="pointer"
      />
      <div className="title-modal">ATTENTION</div>
      <div className="subtitle-modal">
        Une fois abonné(e) a la page de l&apos;établissement, cliquez sur la flèche
        en haut a gauche de votre écran pour revenir vers le jeu.
      </div>
      <button
        type="button"
        className="google-button"
        style={{
          maxWidth: '100%',
          background: props?.customStyle?.buttonColor,
        }}
        onClick={() => {
          props.handleClick();
          props.setOpenDisclaimer(false);
        }}
      >
        <span className="google-button__text">Abonnez vous</span>
      </button>
    </div>
  );
}

export default DisclaimerModal;
