/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import validator from "validator";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import gift from "../../ressources/pictures/gift2.svg"
import clap from "../../ressources/pictures/clap.svg"
import environment from "../../../environments/environment";

export default class MailForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mail: "",
			phone: "",
			firstName: "",
			lastName: "",
			marketing: false,
			isMailInvalid: false,
			isSent: false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleSubmit(event) {
		event.preventDefault();

		this.sendConfirmation()
	}

	handleMarketingChange(e) {
		this.setState(prevState => ({
			marketing: !prevState.marketing
		}));
	}

	async handleShareClickSponsorShip() {
		const sharedData = { title: "Partagez a votre ami(e)", text: `J'ai un bon plan pour toi, tente de gagner ${this.props?.sharedGift} chez ${this.props?.name} : http://app.dokaa.app?qrCode=${this.props?.sharedWheel}` }
		await navigator.share(sharedData);
	}

	checkEmailValidity(event) {
		if (validator.isEmail(event.target.value)) {
			this.state.isMailInvalid = false;
		} else {
			this.state.isMailInvalid = true;
		}
		this.forceUpdate();
	}


	sendFormCTAConfirmation() {
		const data = {
			wheelId: this.props.wheelId,
			shopId: this.props.shop._id,
			user: {
				mail: this.props.user.email,
				firstName: this.props.user.firstName,
				lastName: this.props.user.lastName,
				phone: this.props.user.phone,
			},
			userSeed: this.props.uuid,
			wheelCtaType: this.props.pickedCta.type,
			marketing: true,
			gift: {
				name: this.props.giftRecived.name,
				...(this.props.giftRecived.digitalOffer && { digitalOffer: this.props.giftRecived.digitalOffer }),
				...(this.props.giftRecived.discountType && { discountType: this.props.giftRecived.discountType })
			}
		};

		axios.post(`${environment.REACT_APP_API_URL}/wheels-history/${this.props.wheelHistoryId}`, data)
			.then(res => {
				this.state.isSent = true;
				this.forceUpdate();
			})
			.catch(err => {
				console.log(err.response.data.message)
				this.notify(err.response.data.message)
			})
	}

	sendConfirmation() {
		const data = {
			wheelId: this.props.wheelId,
			shopId: this.props.shop._id,
			user: {
				...(!!this.props.wheel?.wheelCampaignField?.mail && { mail: this.state.mail }),
				...(!!this.props.wheel?.wheelCampaignField?.phone && { phone: this.state.phone }),
			},
			wheelCtaType: this.props.pickedCta.type,
			marketing: this.state.marketing,
			userSeed: this.props.uuid,
			gift: {
				name: this.props.giftRecived.name,
				...(this.props.giftRecived.digitalOffer && { digitalOffer: this.props.giftRecived.digitalOffer }),
				...(this.props.giftRecived.discountType && { discountType: this.props.giftRecived.discountType })
			}
		};

		axios.post(`${environment.REACT_APP_API_URL}/wheels-history/${this.props.wheelHistoryId}`, data)
			.then(res => {
				this.state.isSent = true;
				this.forceUpdate();
			})
			.catch(err => {
				console.log(err.response.data.message)
				this.notify(err.response.data.message)
			})
	}

	// eslint-disable-next-line class-methods-use-this
	notify(text) {
		toast.error(text, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "colored",
		});
	}


	render() {
		const { giftRecived, isLose, pickedCta, sharedGift, wheel } = this.props;

		return (
			<>
				{isLose ?
					<div className="mail-form">
						<div className="title-modal">Désolé...</div>
						<p>Vous n&apos;avez pas gagné cette fois-ci mais vous pouvez retentez votre chance !</p>
					</div>
					:
					<div className="mail-form">
						{this.state.isSent ?
							<div>
								{pickedCta.sponsorship
									?
									<div style={{ padding: '0 16px', display: 'flex', flexDirection: "column", alignItems: "center" }}>
										<div className="title-modal">
											<div className="flex items-center justify-center gap-2">
												<img style={{ width: '10%' }} src={clap} alt="gift" />
												Bravo
												<img style={{ width: '10%', transform: 'scaleX(-1)' }} src={clap} alt="gift" />
											</div>
										</div>
										<div style={{ display: 'flex', flexDirection: "column", alignItems: "center", textAlign: "center" }} >
											<p>Votre participation a bien été prise en compte, un mail vient de vous être envoyé.</p>
										</div>
										<img style={{ margin: '20px 0', width: '30%' }} src={gift} alt="gift" />
										<p style={{ padding: '0 20px', fontWeight: "600", textAlign: "center", marginBottom: "16px" }}>Partagez à un(e) ami(e) et tentez de lui faire gagner {sharedGift}</p>
										<button
											className="google-button"
											type="button"
											style={{ maxWidth: "100%", background: this.props?.customStyle?.buttonColor }}
											onClick={this.handleShareClickSponsorShip.bind(this)}
										>
											<span className="google-button__text">Envoyer a un ami</span>
										</button>
									</div>
									:
									<div style={{ padding: '0 16px', display: 'flex', flexDirection: "column", alignItems: "center" }}>
										<div className="title-modal">
											<div className="flex items-center justify-center gap-2">
												<img style={{ width: '10%' }} src={clap} alt="gift" />
												Bravo
												<img style={{ width: '10%', transform: 'scaleX(-1)' }} src={clap} alt="gift" />
											</div>
										</div>
										<p style={{ textAlign: "center" }}>Votre participation a bien été prise en compte, un mail vient de vous être envoyé. Vous pouvez fermer cette page.</p>
										<img style={{ margin: '20px 0', width: '50%' }} src={gift} alt="gift" />
									</div>
								}
							</div>
							:
							<>
								<div className="title-modal">
									Félicitations, Vous avez gagné <br /><span className="title-modal-alt" style={{ color: this.props?.customStyle?.buttonColor }}> {giftRecived?.digitalOffer ? `-${giftRecived?.name}${giftRecived?.discountType === "percent" ? "%" : "€"}` : giftRecived?.name}</span>
								</div>
								{wheel.claimImediate ?
									<div style={{ padding: '0 16px', display: 'flex', flexDirection: "column", alignItems: "center" }}>
										<p className="core-text">
											Vous pouvez récupérer votre cadeau dès maintenant en montrant cet écran a un(e) employé(e) de l&apos;établissement.
										</p>
										<img style={{ margin: '16px 0', width: '20%' }} src={gift} alt="gift" />
										{pickedCta.sponsorship ?
											<>
												<p className="core-text mb-4">Partagez à un(e) ami(e) et tentez de lui faire gagner <b>{sharedGift}</b></p>
												<button
													className="google-button"
													type="button"
													style={{ maxWidth: "100%", background: this.props?.customStyle?.buttonColor }}
													onClick={this.handleShareClickSponsorShip.bind(this)}
												>
													<span className="google-button__text">Envoyer a un ami</span>
												</button>
											</> :
											""
										}
									</div>
									:
									<>
										{pickedCta.type === "form" ?
											<div style={{ padding: '0 16px', display: 'flex', flexDirection: "column", alignItems: "center" }}>
												<p className="core-text">
													Vous pouvez récupérer votre cadeau à utiliser <b>{giftRecived?.digitalOffer ? "lors de votre prochaine commande" : "lors de votre prochaine venue"}</b> en cliquant sur &quot;Je recois mon cadeau&quot;.
												</p>
												<img style={{ margin: '16px 0', width: '30%' }} src={gift} alt="gift" />
												<button
													className="google-button"
													type="button"
													style={{ margin: '20px 0', maxWidth: "100%", background: this.props?.customStyle?.buttonColor }}
													onClick={this.sendFormCTAConfirmation.bind(this)}
												>
													<span className="google-button__text">Je recois mon cadeau</span>
												</button>
											</div>
											:
											<>
												{wheel?.gameType !== 'raffle' ?
													<p className="core-text">
														Afin de récupérer votre cadeau à utiliser <b>{giftRecived?.digitalOffer ? "lors de votre prochaine commande" : "lors de votre prochaine venue"}</b>
														&nbsp;
														{wheel?.wheelCampaignField?.mail && !wheel?.wheelCampaignField?.phone && "saisissez votre e-mail"}
														{wheel?.wheelCampaignField?.phone && !wheel?.wheelCampaignField?.mail && "saisissez votre numero de téléphone"}
														{wheel?.wheelCampaignField?.phone && wheel?.wheelCampaignField?.mail && "saisissez votre e-mail et numero de téléphone"}
													</p> :
													<p className="core-text">
														Afin de valider votre participation saisissez <b>votre e-mail</b>
													</p>
												}
												<div style={{ display: 'flex', flexDirection: 'column', margin: 'auto', width: '90%' }}>
													{wheel?.wheelCampaignField?.mail &&
														<input
															type="text"
															id="mail"
															name="mail"
															className="custom-form"
															placeholder="E-mail"
															autoComplete="off"
															value={this.state.mail}
															onBlur={this.checkEmailValidity.bind(this)}
															onChange={this.handleChange.bind(this)}
															required
														/>
													}
													{wheel?.wheelCampaignField?.phone &&
														<input
															type="tel"
															id="phone"
															name="phone"
															className="custom-form"
															placeholder="Numero de téléphone"
															autoComplete="off"
															value={this.state.phone}
															onChange={this.handleChange.bind(this)}
															required
														/>
													}
													<span
														style={{
															fontType: "italic",
															color: "red",
															visibility: this.state.isMailInvalid ? "visible" : "hidden",
														}}
													>
														Adresse mail invalide, verifiez votre saisie
													</span>
													<div className="marketing">
														<input type="checkbox" id="marketing" name="marketing" value={this.state.marketing} onChange={this.handleMarketingChange.bind(this)} />
														<label htmlFor="marketing">J’accepte de recevoir les offres promotionnelles de la part de {this.props.name}</label>
													</div>
												</div>

												<button
													className="google-button"
													type="button"
													onClick={this.handleSubmit}
													disabled={this.state.isMailInvalid}
													style={{ background: this.props?.customStyle?.buttonColor }}
												>
													<span className="google-button__text">Je recois mon cadeau</span>
												</button>
											</>
										}
									</>
								}

								<ToastContainer
									position="top-center"
									autoClose={5000}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme="colored"
								/>
							</>
						}
					</div >
				}
			</>
		);
	}
}