
const setData = (content) => ({
		type: "SET_DATA",
		content
	})

const appendData = (obj) => (dispatch) => {
		dispatch(setData(obj));
	}

export default {
	appendData
}